<template>
	<div class="articleHonor">
		<w-navTab titleText="荣誉榜"></w-navTab>
		<div class="container">
			<div class="header">
				<div class="header-image">
					<img src="@/assets/img/icon63.png" mode=""></img>
				</div>
				<div class="header-content">
					<div class="header-title">数据更新于 {{newTime}}</div>
					<div class="header-nav">
						<div class="header-nav-item" v-for="(item,index) in navList" :key="index"
							@click="onClickNav(index)">
							<div class="nav-item-text">{{item.name}}</div>
							<div class="nav-item-icon">
								<img src="@/assets/img/icon64.png" mode="" v-if="navIndex == index"></img>
							</div>
						</div>
					</div>
				</div>
			</div>
			<w-list :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
				<div class="body">
					<div class="body-list">
							<div class="body-list-item" v-for="(item,index) in honorList" :key="index"
								@click="onPreview(honorList,index)">
								<div class="list-item-img">
									<img :src="item.thumb" mode=""></img>
								</div>
								<div class="list-item-text">“{{item.name}}”</div>
							</div>
					</div>
				</div>
			</w-list>
		</div>
	</div>
</template>

<script>
	import NewsApi from '@/api/news';
	import WList from '@/components/List';
	import Vant from '@/vendor/vant';
	import Moment from '@/vendor/moment';
	// import keepMiXin from '@/mixins/keep';
	// import scrollMiXin from '@/mixins/scroll';
	// import UtilsHelper from '@/vendor/utils';

	// const keep = keepMiXin(['NewsDetail']);
	// const scroll = scrollMiXin(['.list-view']);

	export default {
		name: 'ArticleHonor',
		// mixins: [scroll, keep],
		// inject: ['className'],
		data() {
			return {
				navIndex: 0,
				navList: [{
					id: 2,
					name: '集体荣誉'
				}, {
					id: 1,
					name: '个人荣誉'
				}],
				newTime: '',
				honorList: [],
				loading: true,
				finished: false,
				isEmpty: false,
				paramsPage: 1,
				paramsLimit: 10,
			};
		},
		created() {
			this.newTime = Moment().format('YYYY-MM-DD')
			this.onLoad();
		},
		methods: {
			onClickNav(index) {
				this.navIndex = index
				this.paramsPage = 1
				this.honorList = []
				this.finished = false
				this.isEmpty = false
				this.onLoad()
			},
			onPreview(urlList, index) {
				let list = []
				urlList.forEach(item=>{
					list.push(item.thumb)
				})
				Vant.ImagePreview({
					images: list,
					startPosition: index,
				});
			},
			onLoad() {
				this.loading = true;

				let params = {
					type: this.navList[this.navIndex].id,
					page: this.paramsPage,
					limit: this.paramsLimit,
				};

				NewsApi.honorList(params)
					.then(result => {
						this.paramsPage++;
						this.honorList = this.honorList.concat(result.data);

						this.loading = false;

						if (this.honorList.length <= 0) {
							this.isEmpty = true;
						}

						if (this.honorList.length >= result.data.length) {
							this.finished = true;
						}
					})
					.catch(error => {
						Vant.Toast.fail(error.msg);
					});
			}
		},
		components: {
			WList,
		}
	};
</script>

<style lang="less" scoped>
	.articleHonor {
		.container {
			.header {
				.header-image {
					width: 100%;
					height: 146px;

					img {
						width: 100%;
						height: 146px;
						object-fit: cover;
					}
				}

				.header-content {
					margin-top: -70px;

					.header-title {
						font-size: 12px;
						line-height: 18px;
						color: #FFF;
						margin-left: 25px;
					}

					.header-nav {
						margin: 14px 66px 0;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.header-nav-item {
							padding-top: 9px;
							box-sizing: border-box;

							.nav-item-text {
								font-size: 15px;
								line-height: 23px;
								color: #FFF;
							}

							.nav-item-icon {
								width: 23px;
								height: 9px;
								margin: -3px auto;

								img {
									width: 23px;
									height: 9px;
									display: block;
									object-fit: cover;
								}
							}
						}
					}
				}
			}

			.body {
				padding: 20px 4px 0;
				box-sizing: border-box;

				.body-list {
					display: flex;
					// align-items: center;
					flex-wrap: wrap;

					.body-list-item {
						width: 133px;
						// height: 136px;
						margin: 0 25px 16px 25px;

						.list-item-img {
							width: 133px;
							height: 100px;

							img {
								width: 133px;
								height: 100px;
								border-radius: 4px;
								object-fit: cover;
							}
						}

						.list-item-text {
							margin-top: 12px;
							font-size: 16px;
							line-height: 24px;
							text-align: center;
							background: rgba(51, 119, 255, .1);
							border-radius: 17px;
							color: #3377FF;
						}
					}
				}
			}
		}

		.list-view {
			height: 100vh;
		}
	}
</style>